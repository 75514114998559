<template>
  <ImportList />
</template>

<script>
import ImportList from "@/views/retailer/ImportListV2.vue";
export default {
  components: { ImportList },
};
</script>

<style lang="scss" scoped></style>
